import { ofType } from "redux-observable";
import { catchError, switchMap, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { push } from "connected-react-router";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { profilePath } from "Profile/profileRoutes";
import { validateEmployeeDetails$ } from "api/workday";
import {
  activateEmployeeProfile,
  activateEmployeeProfileFailed,
  activateEmployeeProfileFulfilled,
  activateEmployeeProfileCancel,
  fetchProfileFulfilled,
  updateUserProfileFulfilled,
} from "store/profile";
import { fetchBookingHistoryFulfilled } from "store/bookingHistory";
import { fetchMemberDetailsFulfilled } from "store/memberDetails";
import { fetchProfileWithBookingHistory$ } from "store/profile/epics/fetchProfile";
import { fetchEmployeeProfileFulfilled } from "store/employeeProfile";
import { updateUserProfile$ } from "api/tretail/profile";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import catchInternalServerError from "store/catchInternalServerError";

export default function activateEmployeeProfileEpic(action$) {
  return action$.pipe(
    ofType(activateEmployeeProfile.type),
    switchMap(({ payload: { locale, profileToActivate, ...formValues } }) => {
      return ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => {
          return validateEmployeeDetails$(formValues).pipe(
            mergeMap((employeeResponse = {}) => {
              const updateProfilePayload = {
                body: {
                  uniqueId: profileToActivate.uniqueId,
                  salesForceId: profileToActivate.salesForceId,
                  extension: {
                    employeeId: formValues.workdayId,
                  },
                },
                locale,
              };
              return updateUserProfile$(updateProfilePayload).pipe(
                switchMap(() => {
                  return fetchProfileWithBookingHistory$({ locale }).pipe(
                    mergeMap(({ profile, bookingHistory, memberDetails }) => {
                      return [
                        fetchEmployeeProfileFulfilled(employeeResponse),
                        fetchMemberDetailsFulfilled(memberDetails),
                        fetchBookingHistoryFulfilled(bookingHistory),
                        fetchProfileFulfilled(profile),
                        updateUserProfileFulfilled(),
                        activateEmployeeProfileFulfilled(),
                        push(profilePath.to({ locale })),
                      ];
                    })
                  );
                })
              );
            }),

            catchInternalServerError(),

            catchError(({ response = {} }) =>
              of(
                activateEmployeeProfileFailed(
                  response.apiErrors || response.supplierErrors
                    ? {
                        apiErrors: response.apiErrors || [],
                        supplierErrors: response.supplierErrors || [],
                      }
                    : {
                        errors: [
                          {
                            errorCode:
                              response.error || TECHNICAL_ERROR_MESSAGE,
                          },
                        ],
                      }
                )
              )
            ),
            takeUntil(action$.pipe(ofType(activateEmployeeProfileCancel.type)))
          );
        })
      );
    })
  );
}
