import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatchWithLocale } from "hooks";
import { getEmployeeProfile$ } from "api/workday";
import {
  selectIsEmployeeProfile,
  fetchEmployeeProfileFulfilled,
} from "store/employeeProfile";
import { selectEmployeeId } from "store/profile";

export default function useValidEmployee({ locale }) {
  const dispatchWithLocale = useDispatchWithLocale();
  const isEmployeeProfile = useSelector(selectIsEmployeeProfile);
  const workdayId = useSelector(selectEmployeeId);

  const [isvalidatingEmployee, setIsvalidatingEmployee] = useState(
    workdayId && !isEmployeeProfile
  );
  const [redirectToProfile, setRedirectToProfile] = useState(false);

  useEffect(() => {
    if (workdayId) {
      if (!isEmployeeProfile) {
        getEmployeeProfile$({
          locale,
          workdayId,
        }).subscribe(
          (employeeResponse) => {
            setIsvalidatingEmployee(false);
            dispatchWithLocale(fetchEmployeeProfileFulfilled(employeeResponse));
          },
          () => {
            setIsvalidatingEmployee(false);
            setRedirectToProfile(true);
          }
        );
      }
    } else {
      setRedirectToProfile(true);
    }
  }, [isEmployeeProfile, locale, workdayId, dispatchWithLocale]);

  return {
    isvalidatingEmployee,
    redirectToProfile,
  };
}
