import React from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { useDispatchWithLocale, useTranslation } from "hooks";
import { Button, Dropdown, TextInput } from "Profile/components";
import { THEMES } from "contexts/ThemeContext";
import useWorkplaces from "hooks/useWorkplaces";
import { logout, activateEmployeeProfile } from "store/profile/profile.slice";
import { selectAllProfileErrors } from "store/profile";
import ErrorMessage from "../components/ErrorMessage";
import Modal from "./Modal";

export default function EmployeeActivateProfileModal({
  profileToActivate = {},
}) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const { workplaces } = useWorkplaces({
    employeeMode: true,
  });
  const { surname } = profileToActivate;
  const { errorCode } = (useSelector(selectAllProfileErrors) || [])[0] || {};

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      employeeId: "",
      workPlace: "",
    },
  });
  const { isValid } = formState;

  const onSubmit = (formValues) => {
    dispatchWithLocale(
      activateEmployeeProfile({
        surname,
        profileToActivate,
        ...formValues,
      })
    );
  };

  const close = () => {
    dispatchWithLocale(logout());
  };

  return (
    <Modal close={close} theme={THEMES.LIGHT}>
      <div className="view--profile-folio">
        <div className="container">
          <div className="view--profile-folio__header">
            <h1>{t("Activate Employee Profile")}</h1>
            <p className="intro">
              {t(
                "To proceed, add your Workday Employee ID and select your Work Place, then click Activate."
              )}
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {errorCode && <ErrorMessage>{t(errorCode)}</ErrorMessage>}

            <div>
              <TextInput
                identifier="workdayId"
                name="workdayId"
                label={t("Workday Employee Id")}
                placeholder={t("Workday Employee Id")}
                register={register({ required: true })}
                required
              />

              <Dropdown
                label={t("Work Place")}
                name="workPlace"
                options={workplaces.map(({ title, location }) => ({
                  value: location,
                  label: title,
                }))}
                register={register({ required: true })}
                required
              />
            </div>

            <div className="view--profile-folio__actions">
              <Button className="employee-activate" onClick={close}>
                {t("Back")}
              </Button>
              <Button
                className="employee-activate"
                type="submit"
                disabled={!isValid}
              >
                {t("Activate")}
              </Button>
            </div>
          </form>
        </div>
        <button className="btn-close" type="button" onClick={close}>
          <span>{t("Back")}</span>
        </button>
      </div>
    </Modal>
  );
}
