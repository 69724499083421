import { ofType } from "redux-observable";
import {
  catchError,
  switchMap,
  takeUntil,
  mergeMap,
  withLatestFrom,
} from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { push } from "connected-react-router";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { logout$ } from "api/tretail/authentication";
import { clearEmployeeProfile } from "store/employeeProfile/employeeProfile.slice";
import { memberDetailsReset } from "store/memberDetails/memberDetails.slice";
import {
  logout,
  logoutFulfilled,
  logoutCancel,
} from "store/profile/profile.slice";
import { resetRegistration } from "store/registration/registration.slice";
import { clearBookingHistory } from "store/bookingHistory";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import catchInternalServerError from "store/catchInternalServerError";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import { generateOtpReset } from "store/signIn";

export default function logoutEpic(action$, state$) {
  return action$.pipe(
    ofType(logout.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { locale, redirectToSignInView = true },
        },
        state,
      ]) => {
        const isEmployee = selectIsEmployeeProfile(state);
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            return logout$({ locale }).pipe(
              catchInternalServerError(),

              catchError(() => of(EMPTY)),

              mergeMap(() =>
                [
                  clearBookingHistory(),
                  memberDetailsReset(),
                  resetRegistration(),
                  generateOtpReset(),
                  logoutFulfilled(),
                  clearEmployeeProfile(),
                  redirectToSignInView &&
                    push(
                      isEmployee
                        ? authenticationRoutes.employeeSignIn.to({ locale })
                        : authenticationRoutes.signIn.to({ locale })
                    ),
                ].filter(Boolean)
              ),

              takeUntil(action$.pipe(ofType(logoutCancel.type)))
            );
          })
        );
      }
    )
  );
}
