import React, { useEffect } from "react";

import "../../stylesheets/sass/profile_2018.scss";
import { Helmet } from "react-helmet-async";
import { getIsoCodeForLocale } from "config/languages";
import { useHistory } from "react-router-dom";
import { useTranslation, useView } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { profilePath } from "Profile/profileRoutes";
import { getHMTLTagClassNames } from "utils";
import ProfileLayout from "../ProfileLayout";
import {
  BookingOptionsForm,
  LanguageSupportModal,
  ErrorBanner,
} from "./components";
import useEmployeePortal from "./hooks/useEmployeePortal";
import useValidEmployee from "./hooks/useValidEmployee";

function EmployeePortalView() {
  const { i18n, locale, t } = useTranslation();
  const history = useHistory();
  const { searchData, showLoadingIndicator } = useEmployeePortal();
  const { isvalidatingEmployee, redirectToProfile } = useValidEmployee({
    locale,
  });
  const {
    bookingError,
    employeeError,
    error,
    missingLanguageError,
    dismissMissingLanguageError,
  } = searchData;

  useEffect(() => {
    if (redirectToProfile) {
      history.replace(profilePath.to({ locale }));
    }
  }, [redirectToProfile, locale, history]);

  useView("EmployeePortalView");

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
      </Helmet>
      <ProfileLayout>
        {(showLoadingIndicator || isvalidatingEmployee) && (
          <BookingFlowLoadingIndicator />
        )}
        <div className="employee-portal">
          {(bookingError || employeeError || error) && (
            <ErrorBanner
              bookingError={bookingError}
              employeeError={employeeError}
              error={error}
            />
          )}

          <div className="form-container">
            <h1 className="ty-h3">{t("Employee Travel")}</h1>

            <BookingOptionsForm searchData={searchData} />
          </div>
        </div>

        {missingLanguageError && (
          <LanguageSupportModal
            continuePath={missingLanguageError.continuePath}
            closeModal={dismissMissingLanguageError}
          />
        )}
      </ProfileLayout>
    </>
  );
}

export default EmployeePortalView;
